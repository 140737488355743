import { toastController  } from '@ionic/vue';
import { checkmarkCircleOutline, warningOutline,closeCircleOutline } from 'ionicons/icons';


export default function tools() {    

    const toast = async (message:string, color:'success'|'warning'|'danger',duration=2500) =>{
        let _color = 'succcess' // por defecto
        switch(color){
            case 'success': _color = checkmarkCircleOutline; break;
            case 'warning': _color = warningOutline; break;
            case 'danger': _color = closeCircleOutline; break;
        }
        const _toast = await toastController.create({
            message: message,
            duration: duration,
            position: 'top',
            color: color,
            icon: _color,
          });
  
          await _toast.present();
    }

    
      //arreglar el formato de datos que viene del servidor y lo transforma en un json estructurado
      const arreglarDatos_Disponibilidad = (dispo) =>{
        const tmp =  []

        //sacamos las diferentes horas disponibles
        let horas = dispo.map((val)=> val['hora'])
        horas = [...new Set(horas)]; //sacamos valores unicos
        //buscamos para cada hora las distintas pistas disponibles
        horas.forEach(element => {
          const level2= {
            hora: element,
            pistas: dispo.filter(val=> val.hora==element && !val.reservada ).map(val=> {return {
                                                                                                name: val['pista'], 
                                                                                                id: val['pista_id'],
                                                                                                players: val['players_pista'],
                                                                                                players_reserva: val['players_reserva'],
                                                                                              }} )
          }
          tmp.push(level2)
        });

        return tmp
      }


    return {
        toast,arreglarDatos_Disponibilidad
    }
  }