// stores/currentUser.js

import { defineStore } from 'pinia';
import { User } from '@/interfaces/User';
import { RappelCredito } from '@/interfaces/RappelCredito';
import { Reservation } from '@/interfaces/Reservation';
import { ref } from 'vue';

export const currentUserStore = defineStore('currentUser', {
  state: () => ({
    user: null as User | null,
    reservations: ref<Reservation[]>([]),
    rappelCreditos: ref<RappelCredito[]>([]),
  }),

  actions:{
    update(myUser : User)
    {
        this.user = myUser
    }
  }
});