import axios, { AxiosError } from 'axios';
import {User} from '@/interfaces/User';
import {Transaction} from '@/interfaces/Transaction';
import tools from "@/composables/tools";
import { currentUserStore } from '@/stores/currentUser';
import moment from 'moment';

export abstract class UserServices{
 
    static async register(myUser:User) : Promise<User>{
        try {
            const response = await axios.post('register',myUser)
            return response.data
        } catch (error) {

            if(axios.isAxiosError(error)){
                myUser.errors = error.response.data.errors
                console.log(myUser)
                return myUser
            }
        }
    }

    static async getTransactions(): Promise<Transaction[]>{
        try {
            const response = await axios.get('transactions')
            return response.data.data
        } catch (error) {
            console.log(error)
        }
    }

    static async reloadReservations(){
        try {
            const response = await axios.get('reservasUser')
            currentUserStore().$state.reservations = response.data

            //tengo que iterar dentro de las reservas futuras para ver cuantos jugadores hay en total asociados a la reserva ya que no consigo traerlo en una unica llamada
            
            currentUserStore().$state.reservations.forEach( async(val)=>{
                if(val.start_at > moment().subtract(3,'hours').format('Y-MM-DD H:mm:s')){
                const response = await axios.get('reservation/numPlayers',{params:{start_at:val.start_at,pista_id:val.pista_id}})
                val.players = response.data.players
                val.myPlayers = response.data.myPlayers
                }
                else console.log('ignoramos')

            })

        } catch (error) {
            tools().toast('No se pueden cargar las reservas','danger',3000);
        }
    }

    static async getSaldo(): Promise<number>{
        try {
            const response = await axios.get('saldo')
            return response.data
        } catch (error) {
            console.log(error)
        }
    }

    
    static async getRappelCreditos(){
        try {
            const response = await axios.get('rappelCreditos')
            currentUserStore().$state.rappelCreditos = response.data
        } catch (error) {
             console.log(error)
        }
    }

}