import axios from 'axios'
import { useRouter } from 'vue-router'
import { currentUserStore } from '@/stores/currentUser'
import { User } from '@/interfaces/User';

export default function useAuth() {    

    function login(u: string,p: string){
        axios.get('login',{params:{
          email: u,
          password: p
        }})
        .then( response => {
            localStorage.setItem('token',response.data.access_token)
            localStorage.setItem('user',JSON.stringify(response.data.user))
            //** guardamo como state los datos del usuario logeado */
            const userJson = localStorage.getItem('user');
            const currentUserObj:User = userJson !== null ? JSON.parse(userJson):{}
            const currentUser = currentUserStore()
            currentUser.update(currentUserObj)
            location.href="/"
        })
    }

    function logout() : void {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        location.href="/login"
      }

    return {
      login,logout
    }
  }